.boton {
	background: var(--btn);
	color: #fff;
}

.botonWhite {
	background: #fff;
	color: var(--btn);
}

.boton,
.botonWhite {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	border: none;
	cursor: pointer;
	text-transform: capitalize;
	transition: .5s ease all;
	position: relative;
	overflow: hidden;
}
.botonWhite span,
.boton span {
	position: relative;
	z-index: 2;
	transition: .3s ease all;
}

/* primer estilo */
.botonWhite.one::after,
.boton.one::after {
	content: "";
	width: 100%;
	height: 300px;
	position: absolute;
	z-index: 1;
	top: -300px;
	left: 0;
	transition: .6s ease-in-out all;
	border-radius: 0px 0px 300px 300px;
}

.botonWhite.one::after{
	background: rgb(225, 225, 225);
}

.boton.one::after{
	background: var(--btn-hover);
}

.botonWhite.one:hover::after,
.boton.one:hover::after {
	top: 0;
}

/* segundo estilo */
.botonWhite.two::after,
.boton.two::after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	top: -80px;
	left: 0;
	transition: .6s ease-in-out all;
}

.botonWhite.two::after{
	background: rgb(225, 225, 225);
}

.boton.two::after{
	background: var(--btn-hover);
}

.botonWhite.two:hover::after,
.boton.two:hover::after {
	top: 0;
}

/* tercer estilo */
.botonWhite.three::after,
.boton.three::after {
	content: "";
	width: 1px;
	height: 1px;
	background: none;
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	transition: .9s ease-in-out all;
	border-radius: 100px;
	transform-origin: center;
}

.botonWhite.three:hover::after{
	background: rgb(225, 225, 225);
}

.boton.three:hover::after{
	background: var(--btn-hover);

}
.botonWhite.three:hover::after,
.boton.three:hover::after {
	transform: scale(400);
}

/*boton del formulario */

.formContent__btn {
	display: inline-flex !important;
	align-items: center !important;
	justify-content: center !important;
	background: var(--btn) !important;
	color: #fff !important;
	font-weight: 500 !important;
	border: none !important;
	cursor: pointer !important;
	text-transform: capitalize !important;
	transition: .5s ease all !important;
	position: relative !important;
	overflow: hidden !important;
	width: auto !important;
	padding: 16px 24px !important;
	display: flex !important;
	justify-content: flex-start !important;
}

